<template>
  <div class="uk-card uk-card-default uk-card-body uk-padding-remove">
    <h3 class="uk-padding-remove uk-margin-small-bottom uk-margin-small-top uk-margin-small-left">
      {{ title }}
    </h3>
    <span class="uk-padding-small uk-position-top-right"> 
      <router-link v-if="pageLink" :to="pageLink" class="uk-link-toggle ">
        <p class="uk-link-heading" style="display:inline">{{ $t('Generic.Labels.SeeAll') }}</p>
      </router-link>
    </span>
    
    <div class="uk-overflow-auto uk-height-medium">
      <div v-if="!showItems" class="uk-position-center">
        <span :uk-spinner="spinnerConfig"></span>
      </div>
      <BaseTable v-else :tableHeaders="headers" :removeBorderBottom="true" style="overflow-x:hidden">
        <tr v-if="items.length == 0">
          <td :colspan="headers.length">
            <p class="uk-text-center">{{ $t( 'Generic.Labels.NoRecordsAvailable' ) }}</p>
          </td>
        </tr>
        <tr
          v-else
          v-for="(item, index) in items"
          :key="index"
        >
          <td :colspan="headers.length">
            <router-link
              :to="item.routerLink || {}"
              :class="item.routerLink ? 'uk-link-toggle' : 'uk-link-reset'"
              :style="item.routerLink ? '' : 'cursor: default;'"
            >
              <div v-if="typeof item.image === 'undefined'"
                class="uk-card uk-card-defaults uk-padding-small uk-padding-remove-left uk-padding-remove-right">
                <div class="uk-width-expand">
                    <div class="uk-float-left uk-width-3-4">
                      <p class="uk-link-heading uk-margin-remove-bottom uk-width-medium uk-text-truncate" >{{ item.title }}</p>
                    </div>
                    <div class="uk-float-right">
                      <p class="uk-link-heading uk-margin-remove-bottom">{{ item.subtitle }}</p>
                    </div>
                  </div>
              </div>
              <div v-else class="uk-card uk-card-defaults uk-padding-remove">
                <div class="uk-grid-small uk-flex-middle" uk-grid>
                  <div class="uk-width-auto">
                    <ImageOrAbbrevation
                      :src="item.image"
                      :alt="item.imageAlt"
                    />
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-float-left uk-width-3-4">
                      <p class="uk-link-heading uk-margin-remove-bottom uk-width-medium uk-text-truncate" >{{ item.title }}</p>
                    </div>
                    <div class="uk-float-right">
                      <p class="uk-link-heading uk-margin-remove-bottom">{{ item.subtitle }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </td>
        </tr>
      </BaseTable>
    </div>
  </div>
</template>

<script>
import ImageOrAbbrevation from '@/components/images/ImageOrAbbrevation.vue';
import BaseTable from '@/components/tables/BaseTable.vue';

export default {
  name: 'DashboardWidget',
  components: {
    ImageOrAbbrevation,
    BaseTable
  },
  props: {
    pageLink: {
      type: Object,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    showItems: {  // this is helpful if you want to show progress untill the data is fetched
      type: Boolean,
      required: true,
      default: false
    },
    spinnerConfig: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>