var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"uk-text-left"},[_vm._v(_vm._s(_vm.$t('Pages.Dashboard.Title')))]),_c('hr'),_c('div',{staticClass:"js-filter uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl",attrs:{"uk-scrollspy":"target: > div; cls: uk-animation-slide-top-medium; delay: 100; offset-top: 2000;","uk-grid":""}},_vm._l((_vm.permissibleSummaryItems),function(item,i){return _c('div',{key:i},[_c('StatCard',{attrs:{"name":item.name,"value":item.value,"icon":item.icon,"to":item.to}})],1)}),0),_c('div',{staticClass:"uk-text-left uk-grid-medium",attrs:{"uk-grid":""}},[(_vm.isAdmin || _vm.permissionSet.has(_vm.Permissions.CONTENT_MANAGER) || _vm.permissionSet.has(_vm.Permissions.ADMIN))?_c('div',{staticClass:"uk-width-1-3@l uk-width-1-2@m",attrs:{"uk-scrollspy":"target: > div; cls: uk-animation-scale-up; delay: 100; offset-top: 2000;"}},[_c('DashboardWidget',{attrs:{"showItems":_vm.mediaLibrarySummary !== null,"title":_vm.$t( 'Pages.Dashboard.MediaLibrary' ),"pageLink":{ name: 'MediaLibrary', params: { id: this.orgId } },"headers":[{
          name: this.$t( 'Generic.Labels.Name' ),
          class: ''
        }, {
          name: this.$t( 'Generic.Labels.Count' ),
          class: 'uk-text-right'
        }],"items":_vm.mediaLibrarySummaryItems}})],1):_vm._e(),_c('div',{staticClass:"uk-width-1-3@l uk-width-1-2@m",attrs:{"uk-scrollspy":"target: > div; cls: uk-animation-scale-up; delay: 100; offset-top: 2000;"}},[_c('DashboardWidget',{attrs:{"showItems":_vm.publishingItems !== null,"title":_vm.$t( 'Pages.Dashboard.Publishing' ),"pageLink":{ name: 'Publishing', params: { id: this.orgId } },"headers":[{
          name: this.$t('Generic.Labels.Name'),
          class: ''
        }],"items":_vm.publishingItems || []}})],1),(_vm.isAdmin || _vm.permissionSet.has(_vm.Permissions.ADMIN))?_c('div',{staticClass:"uk-width-1-3@l uk-width-1-2@m",attrs:{"uk-scrollspy":"target: > div; cls: uk-animation-scale-up; delay: 100; offset-top: 2000;"}},[_c('DashboardWidget',{attrs:{"showItems":_vm.appsItems !== null,"title":_vm.$t( 'Pages.Dashboard.Apps' ),"pageLink":{ name: 'Apps', params: { id: this.orgId } },"headers":[{
          name: this.$t('Generic.Labels.Name'),
          class: ''
        }, {
          name: this.$t('Generic.Labels.Type'),
          class: 'uk-text-right'
        }],"items":_vm.appsItems || []}})],1):_vm._e(),(_vm.isAdmin || ((_vm.featureSupport && _vm.featureSupport.ads) && (_vm.permissionSet.has(_vm.Permissions.CONTENT_MANAGER) || _vm.permissionSet.has(_vm.Permissions.ADMIN))))?_c('div',{staticClass:"uk-width-1-3@l uk-width-1-2@m",attrs:{"uk-scrollspy":"target: > div; cls: uk-animation-scale-up; delay: 100; offset-top: 2000;"}},[_c('DashboardWidget',{attrs:{"showItems":_vm.adsItems !== null,"title":_vm.$t( 'Pages.Dashboard.Ads' ),"pageLink":{ name: 'Advertising', params: { id: this.orgId } },"headers":[{
          name: this.$t('Generic.Labels.Name'),
          class: ''
        }, {
          name: this.$t( 'Pages.Dashboard.AdType' ),
          class: 'uk-text-right'
        }],"items":_vm.adsItems || []}})],1):_vm._e(),(_vm.isAdmin || _vm.permissionSet.has(_vm.Permissions.ADMIN))?_c('div',{staticClass:"uk-width-1-3@l uk-width-1-2@m",attrs:{"uk-scrollspy":"target: > div; cls: uk-animation-scale-up; delay: 100; offset-top: 2000;"}},[_c('DashboardWidget',{attrs:{"showItems":_vm.usersItems !== null,"title":_vm.$t( 'Pages.Dashboard.Users' ),"pageLink":{ name: 'Users', params: { id: this.orgId } },"headers":[{
          name: this.$t('Generic.Labels.Name'),
          class: ''
        }, {
          name: this.$t('Pages.Dashboard.LastActivity'),
          class: 'uk-text-right'
        }],"items":_vm.usersItems || []}})],1):_vm._e(),(_vm.isAdmin || ((_vm.featureSupport && _vm.featureSupport.interactiveVideos) && (_vm.permissionSet.has(_vm.Permissions.CONTENT_MANAGER) || _vm.permissionSet.has(_vm.Permissions.ADMIN))))?_c('div',{staticClass:"uk-width-1-3@l uk-width-1-2@m",attrs:{"uk-scrollspy":"target: > div; cls: uk-animation-scale-up; delay: 100; offset-top: 2000;"}},[_c('DashboardWidget',{attrs:{"showItems":_vm.ivItems !== null,"title":_vm.$t( 'Pages.Dashboard.InteractiveVideos' ),"pageLink":{ name: 'InteractiveVideo', params: { id: this.orgId } },"headers":[{
          name: this.$t('Generic.Labels.Name'),
          class: ''
        }, {
          name: this.$t('Pages.Dashboard.CreatedDate'),
          class: 'uk-text-right'
        }],"items":_vm.ivItems || []}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }