<template>
  <router-link :to="to" class="uk-link-toggle">
    <div class="uk-card uk-card-default uk-card-hover uk-padding-small">
      <div class="uk-grid-small uk-flex-middle" uk-grid>
        <div class="uk-card">
          <span :uk-icon="icon"></span>
        </div>
        <div class="uk-card uk-text-left uk-width-3-4 uk-text-truncate">
          <label class="uk-text-truncate">{{ name }}</label>
          <h2 class="uk-card-title uk-margin-small-top">{{ value }}</h2>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'StatCard',
  components: {
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    icon: {
      type: String,
      default: 'icon: bolt; ratio: 1.5'
    },
    to: {
      type: Object,
      required: true
    }
  },
  computed: {
  }
}
</script>