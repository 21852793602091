<template>
  <div>
    <h1 class="uk-text-left">{{ $t('Pages.Dashboard.Title') }}</h1>
    <hr />
    <div 
      class="js-filter uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl"
      uk-scrollspy="target: > div; cls: uk-animation-slide-top-medium; delay: 100; offset-top: 2000;"
      uk-grid
    >
      <div 
        v-for="(item, i) in permissibleSummaryItems" 
        :key="i"
      >
        <StatCard 
          :name="item.name" 
          :value="item.value" 
          :icon="item.icon" 
          :to="item.to"
        />
      </div>
    </div>
    <div class="uk-text-left uk-grid-medium" uk-grid>
      <div class="uk-width-1-3@l uk-width-1-2@m" 
        uk-scrollspy="target: > div; cls: uk-animation-scale-up; delay: 100; offset-top: 2000;"
        v-if="isAdmin || permissionSet.has(Permissions.CONTENT_MANAGER) || permissionSet.has(Permissions.ADMIN)" >
        <DashboardWidget
          :showItems="mediaLibrarySummary !== null"
          :title=" $t( 'Pages.Dashboard.MediaLibrary' ) "
          :pageLink="{ name: 'MediaLibrary', params: { id: this.orgId } }"
          :headers="[{
            name: this.$t( 'Generic.Labels.Name' ),
            class: ''
          }, {
            name: this.$t( 'Generic.Labels.Count' ),
            class: 'uk-text-right'
          }]"
          :items="mediaLibrarySummaryItems"
        />
      </div>
      <div class="uk-width-1-3@l uk-width-1-2@m" 
        uk-scrollspy="target: > div; cls: uk-animation-scale-up; delay: 100; offset-top: 2000;">
        <DashboardWidget
          :showItems="publishingItems !== null"
          :title=" $t( 'Pages.Dashboard.Publishing' ) "
          :pageLink="{ name: 'Publishing', params: { id: this.orgId } }"
          :headers="[{
            name: this.$t('Generic.Labels.Name'),
            class: ''
          }]"
          :items="publishingItems || []"
        />
      </div>
      <div class="uk-width-1-3@l uk-width-1-2@m"
        uk-scrollspy="target: > div; cls: uk-animation-scale-up; delay: 100; offset-top: 2000;"
        v-if="isAdmin || permissionSet.has(Permissions.ADMIN)">
        <DashboardWidget
          :showItems="appsItems !== null"
          :title=" $t( 'Pages.Dashboard.Apps' ) "
          :pageLink="{ name: 'Apps', params: { id: this.orgId } }"
          :headers="[{
            name: this.$t('Generic.Labels.Name'),
            class: ''
          }, {
            name: this.$t('Generic.Labels.Type'),
            class: 'uk-text-right'
          }]"
          :items="appsItems || []"
        />
      </div>
      <div class="uk-width-1-3@l uk-width-1-2@m"
        uk-scrollspy="target: > div; cls: uk-animation-scale-up; delay: 100; offset-top: 2000;"
        v-if="isAdmin || ((featureSupport && featureSupport.ads) && (permissionSet.has(Permissions.CONTENT_MANAGER) || permissionSet.has(Permissions.ADMIN)))">
        <DashboardWidget
          :showItems="adsItems !== null"
          :title=" $t( 'Pages.Dashboard.Ads' ) "
          :pageLink="{ name: 'Advertising', params: { id: this.orgId } }"
          :headers="[{
            name: this.$t('Generic.Labels.Name'),
            class: ''
          }, {
            name: this.$t( 'Pages.Dashboard.AdType' ),
            class: 'uk-text-right'
          }]"
          :items="adsItems || []"
        />
      </div>
      <div class="uk-width-1-3@l uk-width-1-2@m"
        uk-scrollspy="target: > div; cls: uk-animation-scale-up; delay: 100; offset-top: 2000;"
        v-if="isAdmin || permissionSet.has(Permissions.ADMIN)">
        <DashboardWidget
          :showItems="usersItems !== null"
          :title=" $t( 'Pages.Dashboard.Users' ) "
          :pageLink="{ name: 'Users', params: { id: this.orgId } }"
          :headers="[{
            name: this.$t('Generic.Labels.Name'),
            class: ''
          }, {
            name: this.$t('Pages.Dashboard.LastActivity'),
            class: 'uk-text-right'
          }]"
          :items="usersItems || []"
        />
      </div>
      <div class="uk-width-1-3@l uk-width-1-2@m"
        uk-scrollspy="target: > div; cls: uk-animation-scale-up; delay: 100; offset-top: 2000;"
        v-if="isAdmin || ((featureSupport && featureSupport.interactiveVideos) && (permissionSet.has(Permissions.CONTENT_MANAGER) || permissionSet.has(Permissions.ADMIN)))">
        <DashboardWidget
          :showItems="ivItems !== null"
          :title=" $t( 'Pages.Dashboard.InteractiveVideos' ) "
          :pageLink="{ name: 'InteractiveVideo', params: { id: this.orgId } }"
          :headers="[{
            name: this.$t('Generic.Labels.Name'),
            class: ''
          }, {
            name: this.$t('Pages.Dashboard.CreatedDate'),
            class: 'uk-text-right'
          }]"
          :items="ivItems || []"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StatCard from '@/components/cards/StatCard.vue';
import DashboardWidget from '@/components/cards/DashboardWidget.vue';
import { Permissions } from '../../utils/enums';

export default {
  name: 'DashboardView',
  data () {
    return {
      Permissions,
      orgId: this.$route.params.id || this.$route.params.orgId
    }
  },
  components: {
    StatCard,
    DashboardWidget,
  },
  computed: {
    ...mapState({
      orgSummary: state => state.venom?.org?.dashboardOrgSummary || null,
      mediaLibrarySummary: state => state.venom?.org?.mediaLibrarySummary || null,
      publishingSummary: state => state.venom?.org?.dashboardPublishingSummary || null,
      appsSummary: state => state.venom?.org?.dashboardAppsSummary || null,
      adsSummary: state => state.venom?.org?.dashboardAdsSummary || null,
      usersSummary: state => state.venom?.org?.dashboardUsersSummary || null,
      ivSummary: state => state.venom?.org?.dashboardIvSummary || null,
      featureSupport: state => state.venom.org.options?.featureSupport
    }),
    ...mapState({
      viewer: state => state.venom.org.viewer
    }),
    isAdmin () {
      return this.viewer?.role === Permissions.ADMIN
    },
    summaryItems() {
      return [{
        name: this.$t( 'Pages.Dashboard.PublishedVideos' ),
        value: this.$options.filters.number( this.orgSummary?.vods?.totalCount || 0 ),
        icon: 'icon: video-camera; ratio: 2',
        to: { name: 'Publishing' },
        permissionLevel: ['ADMIN', 'CONTENT_MANAGER', 'ANALYTICS_MANAGER', 'VIEWER']
      }, {
        name: this.$t( 'Pages.Dashboard.PublishedLiveStreams' ),
        value: this.$options.filters.number( this.orgSummary?.streams?.totalCount || 0 ),
        icon: 'icon: world; ratio: 1.5',
        to: { name: 'Publishing' },
        permissionLevel: ['ADMIN', 'CONTENT_MANAGER', 'ANALYTICS_MANAGER', 'VIEWER']
      }, {
        name: this.$t( 'Pages.Dashboard.Users' ),
        value: this.$options.filters.number( this.orgSummary?.usersConnection?.totalCount || 0 ),
        icon: 'icon: users; ratio: 1.5',
        to: { name: 'Users' },
        permissionLevel: ['ADMIN']
      }, {
        name: this.$t( 'Pages.Dashboard.Apps' ),
        value: this.$options.filters.number( this.orgSummary?.applicationsConnection?.totalCount || 0 ),
        icon: 'icon: nut; ratio: 1.5',
        to: { name: 'Apps' },
        permissionLevel: ['ADMIN']
      }, {
        name: this.$t( 'Pages.Dashboard.Ads' ),
        value: this.$options.filters.number( this.orgSummary?.adsConnection?.totalCount || 0 ),
        icon: 'icon: bolt; ratio: 1.5',
        to: { name: 'Advertising', params: { } },
        permissionLevel: ['ADMIN', 'CONTENT_MANAGER'],
        featureName: 'ads'
      }, {
        name: this.$t( 'Pages.Dashboard.InteractiveVideos' ),
        value: this.$options.filters.number( this.orgSummary?.interactives?.totalCount || 0 ),
        icon: 'icon: play-circle; ratio: 1.5',
        to: { name: 'InteractiveVideo', params: { } },
        permissionLevel: ['ADMIN', 'CONTENT_MANAGER'],
        featureName: 'interactiveVideos'
      }, {
        name: this.$t( 'Pages.Dashboard.DataUsage' ),
        value: this.$options.filters.appropriateBytes( this.orgSummary?.analytics?.dataUsage?.value || 0 ),
        icon: 'icon: info; ratio: 1.5',
        to: { name: 'Analytics', query: { tab: 'usages' } },
        permissionLevel: ['ADMIN', 'CONTENT_MANAGER', 'ANALYTICS_MANAGER'],
        featureName: 'analytics'
      }, {
        name: this.$t( 'Pages.Dashboard.BandwidthUsage' ),
        value: this.orgSummary?.analytics?.bandwidthUsage || this.$t( "Generic.Labels.NotAvailable" ),
        icon: 'icon: info; ratio: 1.5',
        to: { name: 'Analytics', query: { tab: 'usages' } },
        permissionLevel: ['ADMIN', 'CONTENT_MANAGER', 'ANALYTICS_MANAGER'],
        featureName: 'analytics'
      }, {
        name: this.$t( 'Pages.Dashboard.UniqueVisitors' ),
        value: this.$options.filters.number( this.orgSummary?.analytics?.totalUniqueVisitors?.value || 0 ),
        icon: 'icon: users; ratio: 1.5',
        to: { name: 'Analytics', query: { tab: 'visitors' }},
        permissionLevel: ['ADMIN', 'CONTENT_MANAGER', 'ANALYTICS_MANAGER'],
        featureName: 'analytics'
      }, {
        name: this.$t( 'Pages.Dashboard.ActiveUsers' ),
        value: this.$options.filters.number( this.orgSummary?.analytics?.activeUsers?.value || 0 ),
        icon: 'icon: users; ratio: 1.5',
        to: { name: 'Analytics', query: { tab: 'visitors' }},
        permissionLevel: ['ADMIN', 'CONTENT_MANAGER', 'ANALYTICS_MANAGER'],
        featureName: 'analytics'
      }];
    },
    mediaLibrarySummaryItems() {
      return [{
        title: this.$t( 'Generic.Labels.Videos' ),
        subtitle: this.$options.filters.number( this.mediaLibrarySummary?.videos?.interfaceItems?.totalCount || 0 ),
        routerLink: { name: 'MediaLibrary', query: { mediaClass: 'VIDEO' } }
      }, {
        title: this.$t( 'Generic.Labels.LiveStreams' ),
        subtitle: this.$options.filters.number( this.mediaLibrarySummary?.streams?.interfaceItems?.totalCount || 0 ),
        routerLink: { name: 'MediaLibrary', query: { mediaClass: 'LIVE' } }
      }, {
        title: this.$t( 'Generic.Labels.Images' ),
        subtitle: this.$options.filters.number( this.mediaLibrarySummary?.images?.interfaceItems?.totalCount || 0 ),
        routerLink: { name: 'MediaLibrary', query: { mediaClass: 'IMAGE' } }
      }, {
        title: this.$t( 'Generic.Labels.Audio' ),
        subtitle: this.$options.filters.number( this.mediaLibrarySummary?.audio?.interfaceItems?.totalCount || 0 ),
        routerLink: { name: 'MediaLibrary', query: { mediaClass: 'AUDIO' } }
      }, {
        title: this.$t( 'Generic.Labels.Files' ),
        subtitle: this.$options.filters.number( this.mediaLibrarySummary?.file?.interfaceItems?.totalCount || 0 ),
        routerLink: { name: 'MediaLibrary', query: { mediaClass: 'FILE' } }
      }, {
        title: this.$t( 'Generic.Labels.3DModels' ),
        subtitle: this.$options.filters.number( this.mediaLibrarySummary?.model?.interfaceItems?.totalCount || 0 ),
        routerLink: { name: 'MediaLibrary', query: { mediaClass: 'MODEL' } }
      }, {
        title: this.$t( 'Generic.Labels.Subtitles' ),
        subtitle: this.$options.filters.number( this.mediaLibrarySummary?.subtitles?.interfaceItems?.totalCount || 0 ),
        routerLink: { name: 'MediaLibrary', query: { mediaClass: 'SUBTITLES' } }
      }];
    },
    publishingItems() {
      if( this.publishingSummary === null ) return null;
      return this.publishingSummary.map(p => {
        return {
          title: p.i18nName.localized.value,
          imageAlt: p.i18nName.localized.value,
          image: p?.icon?.url || '',
          routerLink: { name: 'Collections', params: { id: this.orgId, propertyId: p.id, propertyName: p.i18nName.localized.value } }
        }
      });
    },
    appsItems() {
      if( this.appsSummary === null ) return null;
      return this.appsSummary.map(p => {
        return {
          title: p.name,
          subtitle: this.$t( `enums.DeviceType.${p.type}` ),
          routerLink: { name: 'AppEdit', params: { id: this.orgId, appId: p.id } }
        }
      });
    },
    adsItems() {
      if( this.adsSummary === null ) return null;
      return this.adsSummary.map(p => {
        return {
          title: p.title,
          subtitle:  this.$t('Pages.Advertising.AdTypes')[p.__typename === 'BannerAd' ? p.size : p.__typename],
          imageAlt: p.title,
          image: p?.logo?.url || '',
          routerLink: { name: 'Advertising', params: { id: this.orgId }, query: { ad: p.id } }
        }
      });
    },
    usersItems() {
      if( this.usersSummary === null ) return null;
      return this.usersSummary.map(p => {
        return {
          title: p.firstName + " " + p.lastName,
          subtitle: this.$options.filters.formatDate( p.lastLogin ),
          routerLink: { name: 'OrgUserProfile', params: { userId: p.id } }
        }
      });
    },
    ivItems() {
      if( this.ivSummary === null ) return null;
      return this.ivSummary.map(p => {
        return {
          title: p.name,
          subtitle: this.$options.filters.formatDate( p.createdAt ),
          routerLink: { name: 'InteractiveVideoDetail', params: { id: this.orgId, ivId: p.id } }
        }
      });
    },
    permissionSet () {
      const permSet = new Set ()
      this.viewer?.permissions?.forEach(permission => {
        permSet.add(permission.type)
      })
      return permSet
    },
    permissibleSummaryItems () {
      if (Permissions,this.viewer?.role === Permissions.ADMIN) return this.summaryItems
      return this.summaryItems.filter(summaryItem => {
        let isAllowed = false
        if (!summaryItem.permissionLevel && !summaryItem.featureName) return true
        else if (!summaryItem.permissionLevel && summaryItem.featureName) {
          return this.featureSupport && this.featureSupport[summaryItem.featureName]
        }
        for (let perm of summaryItem.permissionLevel) {
          if (this.permissionSet.has(perm)) {
            isAllowed = true
            break
          }
        }
        return summaryItem.featureName ? this.featureSupport && this.featureSupport[summaryItem.featureName] && isAllowed : isAllowed
      })
    }
  },
  mounted () {
    document.documentElement.scrollTop = 0
  },
  async beforeMount() {
    await this.setDashboardData();
    const orgAdmin = this.permissionSet.has(Permissions.ADMIN);
    this.getPublishingSummaryData();
    if ((this.viewer?.role !== Permissions.ADMIN) && !(this.permissionSet.has(Permissions.CONTENT_MANAGER) || orgAdmin )) {
      return
    }
    this.getMediaLibrarySummaryData();
    if ( orgAdmin || this.isAdmin ) {
      this.getAppsSummaryData();
      this.getUsersSummaryData();
    }
    if( this.isAdmin || (orgAdmin && this.featureSupport?.ads) || (this.featureSupport?.ads && this.permissionSet.has(Permissions.CONTENT_MANAGER ) ) )
      this.getAdsSummaryData();
    if( this.isAdmin || (orgAdmin && this.featureSupport?.interactiveVideos) || (this.featureSupport?.interactiveVideos && this.permissionSet.has(Permissions.CONTENT_MANAGER ) ) )
      this.getIvSummaryData();
  },
  methods: {
    async setDashboardData () {
      if (!this.viewer) {
        await this.$store.dispatch('getOrgPermissions', this.$route.params.id || this.$route.params.orgId );
      }
      this.$nextTick(async () => {
        this.getOrgSummaryData()
        return
      })
    },
    async getOrgSummaryData() {
      const isAdmin = this.permissionSet.has(Permissions.ADMIN) || this.isAdmin;
      return this.$store.dispatch( 'fetchOrgSummary', {
            orgId: this.orgId,
            admin: isAdmin,
            contentManager: this.isAdmin || (this.permissionSet.has(Permissions.CONTENT_MANAGER) || this.featureSupport?.ads)  ? true : false,
            fetchFirst: 1,
            analyticsEnabled: this.isAdmin || (this.permissionSet.has(Permissions.ANALYTICS_MANAGER) || this.featureSupport?.analytics)  ? true : false,
            ivEnabled: this.isAdmin || (this.permissionSet.has(Permissions.CONTENT_MANAGER) || this.featureSupport?.interactiveVideos)  ? true : false,
          });
    },
    async getMediaLibrarySummaryData() {
      await this.$store.dispatch( 'fetchMediaLibrarySummary', {
        orgId: this.orgId
      });
    },
    async getPublishingSummaryData() {
      await this.$store.dispatch( 'fetchPublishingSummary', {
        orgId: this.orgId
      } );
    },
    async getAppsSummaryData() {
      await this.$store.dispatch( 'fetchAppsSummary', {
        orgId: this.orgId,
        admin: this.permissionSet.has(Permissions.ADMIN) || this.isAdmin,
      } );
    },
    async getAdsSummaryData() {
      await this.$store.dispatch( 'fetchAdsSummary', {
        orgId: this.orgId,
        contentManager: this.permissionSet.has(Permissions.ADMIN) || this.isAdmin || this.permissionSet.has(Permissions.CONTENT_MANAGER),
      } );
    },
    async getUsersSummaryData() {
      await this.$store.dispatch( 'fetchUsersSummary', {
        orgId: this.orgId,
        admin: this.permissionSet.has(Permissions.ADMIN) || this.isAdmin,
      } );
    },
    async getIvSummaryData() {
      await this.$store.dispatch( 'fetchIvSummary', {
        orgId: this.orgId
      } );
    }
  }
}
</script>
